<template>
    <div>
        <div>
            <breadcrumb :breadcrumb="breadcrumbItems"/>
        </div>
        <div>
            <div class="main">
                <div class="top-news">
                    <div class="row">
                        <div class="col-3">
                            <h3 class="title">
                                information
                            </h3>
                            <h2 class="sub-title">
                                {{$t('top_page.list')}}
                            </h2>
                        </div>
                        <div class="col-9">
                            <ul class="list">
                                <li v-for="(item, index) in informationsList.data" :key="index">
                                    <Item :item="item" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Pagination
                    :length="informationsList.last_page"
                    v-model="filter.page"
                    @input="onPageChange"
                    v-if="informationsList.last_page > 1"
                />
            </div>
        </div>
        <MetaTags />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Item from "./components/item.vue";
export default {
  components: {
    Item
  },
  data() {
    return {
      filter: {
        page: 1,
      },
      pagination: {
        type: Object,
        default: () => ({}),
      },
        breadcrumbItems : [
            {
                title : this.$t("menu.breadcrumb.my_page"),
                link : "/my-page"
            },
            {
                title : this.$t("menu.breadcrumb.information_list"),
                link : "#"
            }
        ]
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.filter = this.$router.getQuery(this.filter);
        this.$router.setQuery(this.filter);
        this.fetchMypageInformaiton(this.filter);
      },
    },
  },
  computed: {
    ...mapGetters({
      informationsList: "mypage/getInformation",
    }),
  },
  created() {
    this.filter = this.$router.getQuery(this.filter);
    this.fetchMypageInformaiton(this.filter);
  },
  methods: {
    ...mapActions({
      fetchMypageInformaiton: "mypage/fetchMypageInformation",
    }),
    onPageChange() {
      this.$router.setQuery(this.filter);
    }
  },
};
</script>
<style scoped>
.custom-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>